<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('categories') }} <span class="text-md"> - {{ $t('articles') }}</span>  </h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active"><router-link :to="{ name: 'dashReports'}">{{ $t('dashboard') }}</router-link></li>
                     <li class="breadcrumb-item active"><router-link :to="{ name: 'categories'}">{{ $t('categories') }}</router-link></li>
                     <li v-if="isEditing" class="breadcrumb-item">{{ $t('edit_category') }}</li>
                     <li v-else class="breadcrumb-item">{{ $t('create_category') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="loadingCategory" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing === false ? $t('create_category') : $t('edit_category') }}
                           </h3>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-4">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('name') }}</label>
                                       <input type="text" v-model="category.name" class="form-control" placeholder="Nome">
                                    </div>
                                 </div>

                                 <div class="col-8">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('subcategories') }}</label>
                                       <VueMultiselect
                                          v-model="subcategoriesSelected"
                                          placeholder="Adicionar ou buscar uma subcategoria"
                                          tag-placeholder="Pressione o enter ou clique para adicionar uma nova subcategoria"
                                          deselect-label="Pressione o enter ou clique para remover"
                                          label="name"
                                          track-by="uuid"
                                          :options="subcategoriesOptions"
                                          :multiple="true"
                                          :taggable="true"
                                          @tag="addSubcategory"
                                       >
                                          <template v-slot:noOptions>
                                             <div>
                                                Não há opções disponíveis
                                             </div>
                                          </template>
                                       </VueMultiselect>
                                    </div>
                                 </div>

                                 <div>

                                 </div>

                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>
      </section>
   </div>
</template>

<script>
import {useToast} from "vue-toastification"
import { v4 as uuidV4 } from "uuid"
import VueMultiselect from 'vue-multiselect'
import { mapActions, mapState, mapMutations } from "vuex"
import SaveButton from "../../../components/SaveButton.vue";

export default {
   name: "CategoriesForm",
   components: { VueMultiselect, SaveButton },
   setup() {
      const toast = useToast()
      return { toast }
   },
   computed: {
      ...mapState({
         loadingCategory: state => state.category.currentCategory.loading,
         category: state => state.category.currentCategory.data
      }),
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      },
      customNoOptionsText() {
         return 'Não há opções disponíveis';
      }
   },
   data () {
      return {
         isEditing: false,
         isSaving: false,
         subcategoriesSelected: [],
         subcategoriesOptions: [],
         errors: []
      }
   },
   async created() {
      const categoryUuid = this.$route.params.uuid;
      if (categoryUuid){
         this.isEditing = true;

         await this.getCategory(categoryUuid);
         this.category.subcategories.forEach(item => {
            const subcategory = { uuid: item.uuid, name: item.name };

            this.subcategoriesOptions.push(subcategory)
            this.subcategoriesSelected.push(subcategory)
         });
      }
      else
         this.resetCategory();
   },
   methods: {
      ...mapActions(['getCategory', 'saveCategory', 'getInfoPagination']),
      ...mapMutations({
         setNewUuid: 'SET_NEW_UUID_IN_CURRENT_CATEGORY',
         resetCategory: 'RESET_CURRENT_CATEGORY',
         setSubcategories: 'SET_SUBCATEGORIES_IN_CURRENT_CATEGORY',
         resetSubcategories: 'RESET_SUBCATEGORIES_IN_CURRENT_CATEGORY'
      }),
      addSubcategory (newSubcategory) {
         const subCat = {
            name: newSubcategory,
            uuid: uuidV4()
         }
         this.subcategoriesOptions.push(subCat)
         this.subcategoriesSelected.push(subCat)
      },
      async save () {
         try {
            this.errors = [];
            this.isSaving = true;

            this.resetSubcategories();
            this.subcategoriesSelected.forEach((item) => {
               this.setSubcategories(item);
            });

            if (this.isEditing) {
               await this.saveCategory({type: 'edit', category: this.category});
            }
            else {
               this.setNewUuid(uuidV4());
               await this.saveCategory({type: 'create', category: this.category});
               this.resetCategory();
               this.subcategoriesOptions = [];
               this.subcategoriesSelected = [];
            }

            this.$router.push({ name: 'categories' });

            this.toast.success(this.$t("saved"));
            if (!this.isEditing)
               await this.getInfoPagination();
         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }
         } finally {
            this.isSaving = false;
         }
      }
   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

